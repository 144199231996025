// 生成年份数组的函数 从2021年开始
const generateYearsArray = (startYear) => {
  let currentYear
  if (startYear) {
    currentYear = Number(startYear)
  } else {
    currentYear = new Date().getFullYear()
  }
  const targetYear = 2021;
  let arr = Array.from({ length: currentYear - targetYear + 1 }, (_, index) => currentYear - index);
  return arr
}

//前端数据假分页
function splitArrayIntoChunks(array, chunkSize) {
  return array.reduce((result, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!result[chunkIndex]) {
      result[chunkIndex] = []; // 创建新数组
    }

    result[chunkIndex].push(item);
    return result;
  }, []);
}

//富文本内容移出img标签
function removeImgTags(htmlString) {
  // 创建一个 DOMParser 实例
  const parser = new DOMParser();
  let html1 = htmlString.replace('<p style=\"text-align: center; line-height: 2;\"><br></p>', "")
  let html2 = html1.replace('<p style=\"text-align: left;\"><br></p>', "")
  let html = html2.replace('<p style=\"text-align: right;\"><br></p>', "")

  // 解析 HTML 字符串为文档对象
  const doc = parser.parseFromString(html, 'text/html');

  // 选择所有的 <img> 标签
  const imgTags = doc.querySelectorAll('img');

  // 移除每一个 <img> 标签
  imgTags.forEach(img => img.remove());

  // 返回移除后的 HTML 字符串
  return doc.body.innerHTML;
}


export {
  generateYearsArray,
  splitArrayIntoChunks,
  removeImgTags
}