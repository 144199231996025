import React from "react";
import { useTranslation } from "react-i18next"

export default function Footer() {
  const { t } = useTranslation();
  const lang = localStorage.getItem('lang')
  return (
    <footer className="footer bg-[#00366A] pt-[50px] text-white">
      <div className="mx-10 2xl:max-w-[1440px] flex 2xl:mx-auto items-stretch">
        <div className="pr-10 border-r-[1px] w-[290px]" style={{ borderImage: "linear-gradient(180deg, rgba(200, 200, 200, 0), rgba(174, 174, 174, 1), rgba(151, 151, 151, 0)) 1 1" }}>
          <div className="text-xl font-bold mb-8">{t('footer.hosts')}</div>
          <div className=" text-sm mb-3 break-words cursor-pointer">{t('footer.hosts1')}</div>
          <div className=" text-sm mb-3 break-words cursor-pointer">{t('footer.hosts2')}</div>
        </div>
        <div className="px-10 border-r-[1px] w-[400px]" style={{ borderImage: "linear-gradient(180deg, rgba(200, 200, 200, 0), rgba(174, 174, 174, 1), rgba(151, 151, 151, 0)) 1 1" }}>
          <div className="text-xl font-bold mb-8">{t('footer.organizers')}</div>
          <div className=" text-sm mb-3 break-words cursor-pointer">{t('footer.organizers1')}</div>
          <div className=" text-sm mb-3 break-words cursor-pointer">{t('footer.organizers2')}</div>
          <div className=" text-sm mb-3 break-words cursor-pointer">{t('footer.organizers3')}</div>
          <div className=" text-sm mb-3 break-words cursor-pointer">{t('footer.organizers4')}</div>
          <div className=" text-sm mb-3 break-words cursor-pointer">{t('footer.organizers5')}</div>
          <div className=" text-sm mb-3 break-words cursor-pointer">{t('footer.organizers6')}</div>
          <div className=" text-sm mb-3 break-words cursor-pointer">{t('footer.organizers7')}</div>
        </div>
        <div className="px-10 border-r-[1px] w-[440px]" style={{ borderImage: "linear-gradient(180deg, rgba(200, 200, 200, 0), rgba(174, 174, 174, 1), rgba(151, 151, 151, 0)) 1 1" }}>
          <div className="text-xl font-bold mb-8">{t('footer.thematic')}</div>
          <div className=" text-sm mb-3 break-words cursor-pointer">{t('footer.thematic2')}</div>
          <div className=" text-sm mb-3 break-words cursor-pointer">{t('footer.thematic1')}</div>
          <div className=" text-sm mb-3 break-words cursor-pointer">{t('footer.thematic7')}</div>
          <div className=" text-sm mb-3 break-words cursor-pointer">{t('footer.thematic9')}</div>
          <div className=" text-sm mb-3 break-words cursor-pointer">{t('footer.thematic3')}</div>
          <div className=" text-sm mb-3 break-words cursor-pointer">{t('footer.thematic8')}</div>
          <div className=" text-sm mb-3 break-words cursor-pointer">{t('footer.thematic5')}</div>
          <div className=" text-sm mb-3 break-words cursor-pointer">{t('footer.thematic6')}</div>
          <div className=" text-sm mb-3 break-words cursor-pointer">{t('footer.thematic4')}</div>
        </div>
        <div className="pl-10">
          <div className="text-xl font-bold mb-8">{t('footer.contactus')}</div>
          <div className=" text-sm mb-3 break-words cursor-pointer flex items-center">
            <img src={require("@/imgs/icons/location_white_line.png")} alt="location" />
            <span className="ml-[10px]">{t('footer.contactus1')}</span>
          </div>
          <div className=" text-sm mb-3 break-words cursor-pointer flex items-center">
            <img src={require("@/imgs/icons/email_white.png")} alt="email" />
            <span className="ml-[10px]">{t('footer.contactus2')}</span>
          </div>
          <div className=" text-sm mb-6 break-words cursor-pointer flex items-center">
            <img src={require("@/imgs/icons/qrcode_white.png")} alt="QR code" />
            <span className="ml-[10px]">{t('footer.contactus3')}</span>
          </div>
          <div className="flex items-start justify-between">
            <div className="lg:w-[90px] xl:w-[110px] 2xl:w-[130px]">
              <div className="lg:w-[80px] xl:w-[100px] 2xl:w-[120px] lg:p-1 xl:p-2 2xl:p-[10px] bg-white rounded-[10px] mb-[10px] overflow-hidden">
                <img className="w-full h-auto" style={{ letterSpacing: '-0.8px' }} src={require('@/imgs/footer/website.webp')} alt="website" />
              </div>
              <p className=" text-sm" style={{ letterSpacing: '-0.8px' }}>{t('footer.contactus4')}</p>
            </div>
            <div className="lg:w-[90px] xl:w-[110px] 2xl:w-[130px]">
              <div className="lg:w-[80px] xl:w-[100px] 2xl:w-[120px] lg:p-1 xl:p-2 2xl:p-[10px] bg-white rounded-[10px] mb-[10px] overflow-hidden">
                <img className="w-full h-auto" src={require('@/imgs/footer/transport.png')} alt="website" />
              </div>
              <p className=" text-sm" style={{ letterSpacing: '-0.8px' }}>{t('footer.contactus5')}</p>
            </div>
            <div className="lg:w-[90px] xl:w-[110px] 2xl:w-[130px]">
              <div className="lg:w-[80px] xl:w-[100px] 2xl:w-[120px] lg:p-1 xl:p-2 2xl:p-[10px] bg-white rounded-[10px] mb-[10px] overflow-hidden">
                <img className="w-full h-auto" src={require('@/imgs/footer/shipping.png')} alt="website" />
              </div>
              <p className=" text-sm" style={{ letterSpacing: '-0.8px' }}>{t('footer.contactus6')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center pt-10 pb-6 text-sm">
        <p className="mb-2">上海靖达国际商务会展旅行有限公司 沪ICP备13028959号-6</p>
        <p className="mb-2 text-[#e10103]">{lang === 'zh' ? '大会官方网站内容未经允许，禁止拷贝或转载本站内容，禁止建立类似会议注册页面，否则追究法律责任！' : 'The conference official website content without permission, copied or reproduced prohibited content in this site, prohibited to establish similar conference registration page, otherwise, shall be investigated for legal responsibility!'}</p>
        <p className="flex items-center justify-center">
          <img className="w-5 h-5 mr-2" src={require('@/imgs/footer/gongan.png')} alt="gong an" />
          <span>沪公网安备 31011502018462号</span>
        </p>
      </div>
    </footer>
  )
}  