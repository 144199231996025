import React, { lazy } from "react";

const Home = lazy(() => import("../pages/home/home.jsx"));
const Edm = lazy(() => import("../pages/edm/edm.js"));
const FocusTopic = lazy(() => import("../pages/focusTopic/focusTopic.js"));
const Partners = lazy(() => import("../pages/partners/partners.js"));
const AboutUs = lazy(() => import("../pages/aboutUs/aboutUs.js"));
const Review = lazy(() => import("../pages/review/review.js"));
const LiveCenter = lazy(() => import("../pages/liveCenter/liveCenter.jsx"));
const VideoDetail = lazy(() => import("../components/VideoDetail/VideoDetail.js"));
const NewsCenter = lazy(() => import("../pages/newsCenter/newsCenter.js"));
const NewsCenterDetail = lazy(() => import("../pages/newsCenter/detail/newsCenterDetail.js"))
const GSBN = lazy(() => import("@/pages/newsCenter/leadingEdges/GSBN.js"));
const CHUANSHIBAO = lazy(() => import("@/pages/newsCenter/leadingEdges/CHUANSHIBAO.js"))
const ChinaEasternAirlines = lazy(() => import("@/pages/newsCenter/leadingEdges/ChinaEasternAirlines.js"))
const SmartPort = lazy(() => import("@/pages/newsCenter/leadingEdges/SmartPort.js"))
const InnovationDriven = lazy(() => import("@/pages/newsCenter/leadingEdges/InnovationDriven.js"))
const BOCOM = lazy(() => import("@/pages/newsCenter/leadingEdges/BOCOM.js"))
const Forum = lazy(() => import("@/pages/forum/forum.js"))

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/edm",
    element: <Edm />,
  },
  {
    path: "/focus-topic",
    element: <FocusTopic />,
  },
  {
    path: "/focus-topic/:id",
    element: <VideoDetail />,
  },
  {
    path: "/partners",
    element: <Partners />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/review/:id",
    element: <Review />,
  },
  {
    path: "/review/:id/:id",
    element: <VideoDetail />,
  },
  {
    path: "/live-center",
    element: <LiveCenter />,
  },
  {
    path: "/live-center/:id",
    element: <VideoDetail />,
  },
  {
    path: "/news-center",
    element: <NewsCenter />,
  },
  {
    path: "/news-center/:id",
    element: <NewsCenterDetail />
  },
  {
    path: "/news-center/leading-edges/GSBN",
    element: <GSBN />,
  },
  {
    path: "/news-center/leading-edges/CHUANSHIBAO",
    element: <CHUANSHIBAO />,
  },
  {
    path: "/news-center/leading-edges/China-Eastern-Airlines",
    element: <ChinaEasternAirlines />,
  },
  {
    path: "/news-center/leading-edges/Smart-Port",
    element: <SmartPort />,
  },
  {
    path: "/news-center/leading-edges/Innovation-Driven",
    element: <InnovationDriven />,
  },
  {
    path: "/news-center/leading-edges/BOCOM",
    element: <BOCOM />,
  },
  {
    path: "/forum",
    element: <Forum />
  },
  {
    path: "/high-lights/:id/:id",
    element: <VideoDetail />,
  },
]

export default routes