// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
 
// 语言资源
import enTranslations from '@/locales/en.json';
import zhTranslations from '@/locales/zh.json';
console.log(enTranslations)
const resources = {
  en: {
    translation: enTranslations,
  },
  zh: {
    translation: zhTranslations,
  },
};
 
i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("lang") || 'zh', // 默认语言
  // fallbackLng: localStorage.getItem("lang") || 'zh',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  // debug: true
});
 
export default i18n;