import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom";
import { generateYearsArray } from '@/utils/index';

export default function NavigationBar() {
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const [locale, setLocale] = useState(i18n.language)
  const [pathName, setPathName] = useState(useLocation().pathname)
  const currentPath = useLocation().pathname
  const [hoveredItem, setHoveredItem] = useState(null)
  const [reviewItemsArr, setReviewItemsArr] = useState([])

  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'zh')
      navigate('/');
      window.location.reload()
    }
    const yearArr = generateYearsArray(2023)
    setReviewItemsArr(yearArr)
    // console.log(yearArr)
  }, [])

  const onSelectChange = (lang) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('lang', lang)
    if (process.env.NODE_ENV === 'development') {
      window.location.reload()
      // navigate('/');
      // window.location.reload()
    } else {
      navigate('/');
      window.location.reload()
    }

    // if (locale !== lang) {
    //   Cookies.set('lang',lang)
    //   router.replace('/')
    //   window.location.reload()
    // }
  }

  return (
    <div className=" 2xl:min-w-[1400px] pl-2 pr-4 2xl:pl-[50px] 2xl:pr-10 bg-[#004190] fixed top-0 left-0 right-0 z-[99999999]">
      <div className="flex items-center justify-between text-[#26ADDF]">
        <div className="flex items-stretch">
          <Link to={`/`}>
            <img src={require("@/imgs/navigation/logo.png")} alt="logo" className="w-[138px] h-auto py-3" />
          </Link>

          <div className="ml-10 2xl:ml-14 text-[#26ADDF] text-sm lg:text-base 2xl:text-lg font-bolder flex items-center">
            <Link className="group h-full flex items-center mr-4 xl:mr-6 2xl:mr-10" to={`/`}>
              <span className={` group-hover:text-white pr-[10px] relative after:absolute ${currentPath === '/' ? "nav-active after:group-bg-[url('@/imgs/navigation/pulldown_active.png')]" : ""}`}>{t('nav.home')}</span>
            </Link>
            <Link className="group h-full flex items-center mr-4 xl:mr-6 2xl:mr-10" to={`/focus-topic`}>
              <span className={` group-hover:text-white pr-[10px] relative ${currentPath === '/focus-topic' ? "nav-active" : ''}`}>{t('nav.focus')}</span>
            </Link>
            <Link className="group h-full flex items-center relative mr-4 xl:mr-6 2xl:mr-10" to={`/forum`} onMouseEnter={() => setHoveredItem('forum')} onMouseLeave={() => setHoveredItem(null)}>
              <span className={`group group-hover:text-white pr-[10px] relative after:absolute after:top-1/2 after:mt-[-3px] after:right-[-1px] after:content-[''] after:w-[8px] after:h-[6px] after:bg-[url('@/imgs/navigation/pulldown.png')] group-hover:after:bg-[url('@/imgs/navigation/pulldown_active.png')] after:bg-cover after:bg-center ${currentPath === '/forum' ? "nav-active after:bg-[url('@/imgs/navigation/pulldown_active.png')]" : ""}`} >{t('nav.currentForum')}</span>
              {
                // hoveredItem === "forum" &&${hoveredItem === 'forum' ? 'opacity-100 max-h-[200px]' : 'max-h-0 opacity-0'}
                <div className={`absolute z-50 top-[70px] min-w-[220px] pt-[10px] text-base font-bolder text-center text-[#818181] duration-500 opacity-0 max-h-0 overflow-hidden group-hover:opacity-100 group-hover:max-h-[300px]`} style={{ width: "fit-content", left: 'calc(-110px + 50%)' }}>
                  <div className="bg-white bg-opacity-95 pb-[10px] pt-[5px] nav-hover-con rounded-md">
                    <Link className="bg-white px-5 py-[14px] block duration-300 hover:duration-300 hover:text-[#004190] hover:bg-[#26ADDF] group-hover:bg-opacity-20" to={`/forum#forumAgenda`}>
                      {t('nav.currentForum_item1')}
                    </Link>
                    <Link className="bg-white px-5 py-[14px] block  duration-300 hover:duration-300 hover:text-[#004190] hover:bg-[#26ADDF] group-hover:bg-opacity-20" to={`/forum#speakers`}>
                      {t('nav.currentForum_item2')}
                    </Link>
                  </div>
                </div>
              }
            </Link>
            <Link className="group h-full flex items-center mr-4 xl:mr-6 2xl:mr-10 relative" to={`/news-center`} onMouseEnter={() => setHoveredItem('news-center')} onMouseLeave={() => setHoveredItem(null)}>
              <span className={` group-hover:text-white pr-[10px] relative after:absolute after:top-1/2 after:mt-[-3px] after:right-[-1px] after:content-[''] after:w-[8px] after:h-[6px] after:bg-[url('@/imgs/navigation/pulldown.png')] group-hover:after:bg-[url('@/imgs/navigation/pulldown_active.png')] after:bg-cover after:bg-center ${currentPath.indexOf('/news-center') > -1 ? "nav-active after:bg-[url('@/imgs/navigation/pulldown_active.png')]" : ""}`}>{t('nav.newsCenter')}</span>
              {
                // hoveredItem === "news-center" &&
                <div className={`absolute z-50 top-[70px] min-w-[220px] pt-[10px] text-base font-bolder text-center text-[#818181] duration-500 opacity-0 max-h-0 overflow-hidden group-hover:opacity-100 group-hover:max-h-[300px]`} style={{ width: "fit-content", left: 'calc(-110px + 50%)' }}>
                  <div className="bg-white bg-opacity-95 pb-[10px] pt-[5px] nav-hover-con rounded-md">
                    <Link className="bg-white px-5 py-[14px] block duration-300 hover:duration-300 hover:text-[#004190] hover:bg-[#26ADDF] group-hover:bg-opacity-20" to={`/news-center#news`}>
                      {t('nav.newsCenter_item1')}
                    </Link>
                    {/* <Link className="bg-white px-5 py-[14px] block  duration-300 hover:duration-300 hover:text-[#004190] hover:bg-[#26ADDF] group-hover:bg-opacity-20" to={`/news-center#industryNews`}>
                      {t('nav.newsCenter_item2')}
                    </Link> */}
                    <Link className="bg-white px-5 py-[14px] block duration-300 hover:duration-300 hover:text-[#004190] hover:bg-[#26ADDF] group-hover:bg-opacity-20" to={`/news-center#informationRelease`}>
                      {t('nav.newsCenter_item3')}
                    </Link>
                    <Link className="bg-white px-5 py-[14px] block  duration-300 hover:duration-300 hover:text-[#004190] hover:bg-[#26ADDF] group-hover:bg-opacity-20" to={`/news-center#typicalCases`}>
                      {t('nav.newsCenter_item4')}
                    </Link>
                  </div>
                </div>
              }
            </Link>
            <Link className="group h-full flex items-center mr-4 xl:mr-6 2xl:mr-10 relative" to={`/partners`} onMouseEnter={() => setHoveredItem('partners')} onMouseLeave={() => setHoveredItem(null)}>
              <span className={` group-hover:text-white pr-[10px] relative after:absolute after:top-1/2 after:mt-[-3px] after:right-[-1px] after:content-[''] after:w-[8px] after:h-[6px] after:bg-[url('@/imgs/navigation/pulldown.png')] group-hover:after:bg-[url('@/imgs/navigation/pulldown_active.png')] after:bg-cover after:bg-center ${currentPath.indexOf('/partners') > -1 ? "nav-active after:bg-[url('@/imgs/navigation/pulldown_active.png')]" : ""}`}>{t('nav.partner')}</span>
              {
                // hoveredItem === "partners" &&
                <div className={`bg-white bg-opacity-95 absolute z-50 top-[70px] min-w-[220px] pt-[10px] text-base font-bolder text-center text-[#818181] duration-500 opacity-0 max-h-0 overflow-hidden group-hover:opacity-100 group-hover:max-h-[300px]`} style={{ width: "fit-content", left: 'calc(-110px + 50%)' }}>
                  <div className="pb-[10px] pt-[5px] nav-hover-con rounded-md">
                    <Link className="px-5 py-[14px] block duration-300 hover:duration-300 hover:text-[#004190] hover:bg-[#26ADDF] group-hover:bg-opacity-20" to={`/partners#supportCompany`}>
                      {t('nav.partner_item1')}
                    </Link>
                    <Link className=" px-5 py-[14px] block  duration-300 hover:duration-300 hover:text-[#004190] hover:bg-[#26ADDF] group-hover:bg-opacity-20" to={`/partners#cooperativePartners`}>
                      {t('nav.partner_item2')}
                    </Link>
                    <Link className="px-5 py-[14px] block duration-300 hover:duration-300 hover:text-[#004190] hover:bg-[#26ADDF] group-hover:bg-opacity-20" to={`/partners#mediaPartners`}>
                      {t('nav.partner_item3')}
                    </Link>
                    <Link className=" px-5 py-[14px] block  duration-300 hover:duration-300 hover:text-[#004190] hover:bg-[#26ADDF] group-hover:bg-opacity-20" to={`/partners#specialAcknowledgement`}>
                      {t('nav.partner_item4')}
                    </Link>
                  </div>
                </div>
              }
            </Link>
            <Link className="group h-full flex items-center relative mr-4 xl:mr-6 2xl:mr-10" to={`/live-center`} onMouseEnter={() => setHoveredItem('live-center')} onMouseLeave={() => setHoveredItem(null)} >
              <span className={` group-hover:text-white pr-[10px] relative after:absolute after:top-1/2 after:mt-[-3px] after:right-[-1px] after:content-[''] after:w-[8px] after:h-[6px] after:bg-[url('@/imgs/navigation/pulldown.png')] group-hover:after:bg-[url('@/imgs/navigation/pulldown_active.png')] after:bg-cover after:bg-center ${currentPath.indexOf('/live-center') > -1 ? "nav-active after:bg-[url('@/imgs/navigation/pulldown_active.png')]" : ""}`}>{t('nav.live')}</span>
              {
                // hoveredItem === "live-center" &&
                <div className={`absolute z-50 top-[70px] min-w-[220px] pt-[10px] text-base font-bolder text-center text-[#818181] duration-500 opacity-0 max-h-0 overflow-hidden group-hover:opacity-100 group-hover:max-h-[300px]`} style={{ width: "fit-content", left: 'calc(-110px + 50%)' }}>
                  <div className="bg-white bg-opacity-95 pb-[10px] pt-[5px] nav-hover-con rounded-md">
                    <Link className="bg-white px-5 py-[14px] block duration-300 hover:duration-300 hover:text-[#004190] hover:bg-[#26ADDF] group-hover:bg-opacity-20" to={`/live-center#liveVideos`}>
                      {t('nav.live_item1')}
                    </Link>
                    <Link className="bg-white px-5 py-[14px] block  duration-300 hover:duration-300 hover:text-[#004190] hover:bg-[#26ADDF] group-hover:bg-opacity-20" to={`/live-center#livePhotos`}>
                      {t('nav.live_item2')}
                    </Link>
                  </div>
                </div>
              }
            </Link>
            <Link className="group h-full flex items-center mr-4 xl:mr-6 2xl:mr-10 relative" to={`/review/2023`} onMouseEnter={() => setHoveredItem('review')} onMouseLeave={() => setHoveredItem(null)}>
              <span className={` group-hover:text-white pr-[10px] relative after:absolute after:top-1/2 after:mt-[-3px] after:right-[-1px] after:content-[''] after:w-[8px] after:h-[6px] after:bg-[url('@/imgs/navigation/pulldown.png')] group-hover:after:bg-[url('@/imgs/navigation/pulldown_active.png')] after:bg-cover after:bg-center ${currentPath.indexOf('/review') > -1 ? "nav-active after:bg-[url('@/imgs/navigation/pulldown_active.png')]" : ""}`}>{t('nav.review')}</span>
              {
                // hoveredItem === "review" &&
                <div className={`absolute z-50 top-[70px] min-w-[220px] pt-[10px] text-base font-bolder text-center text-[#818181] duration-500 opacity-0 max-h-0 overflow-hidden group-hover:opacity-100 group-hover:max-h-[300px]`} style={{ width: "fit-content", left: 'calc(-110px + 50%)' }}>
                  <div className="bg-white bg-opacity-95 pb-[10px] pt-[5px] nav-hover-con rounded-md">
                    {
                      reviewItemsArr.map((item, index) => {
                        return (
                          <Link key={index} className=" px-5 py-[14px] block  duration-300 hover:duration-300 hover:text-[#004190] hover:bg-[#26ADDF] group-hover:bg-opacity-20" to={`/review/${item}`}>
                            {t(`nav.review_${item}`)}
                          </Link>
                        )
                      })
                    }

                  </div>
                </div>
              }
            </Link>
            <Link className="group h-full flex items-center relative" to={`/about-us`} onMouseEnter={() => setHoveredItem('about-us')} onMouseLeave={() => setHoveredItem(null)}>
              <span className={` group-hover:text-white pr-[10px] relative after:absolute after:top-1/2 after:mt-[-3px] after:right-[-1px] after:content-[''] after:w-[8px] after:h-[6px] after:bg-[url('@/imgs/navigation/pulldown.png')] group-hover:after:bg-[url('@/imgs/navigation/pulldown_active.png')] after:bg-cover after:bg-center ${currentPath === '/about-us' ? "nav-active after:bg-[url('@/imgs/navigation/pulldown_active.png')]" : ""}`}>{t('nav.aboutUs')}</span>
              {
                // hoveredItem === "about-us" &&
                <div className={`absolute z-50 top-[70px] min-w-[220px] pt-[10px] text-base font-bolder text-center text-[#818181] duration-500 opacity-0 max-h-0 overflow-hidden group-hover:opacity-100 group-hover:max-h-[300px]`} style={{ width: "fit-content", left: 'calc(-110px + 50%)' }}>
                  <div className="bg-white bg-opacity-90 pb-[10px] pt-[5px] nav-hover-con rounded-md">
                    <Link className=" px-5 py-[14px] block duration-300 hover:duration-300 hover:text-[#004190] hover:bg-[#26ADDF] hover:bg-opacity-100 group-hover:bg-opacity-20" to={`/about-us#missions`}>
                      {t('nav.aboutUs_item1')}
                    </Link>
                    <Link className=" px-5 py-[14px] block  duration-300 hover:duration-300 hover:text-[#004190] hover:bg-[#26ADDF] group-hover:bg-opacity-20" to={`/about-us#organizationalStructure`}>
                      {t('nav.aboutUs_item2')}
                    </Link>
                    <Link className="bg-white px-5 py-[14px] block duration-300 hover:duration-300 hover:text-[#004190] hover:bg-[#26ADDF] group-hover:bg-opacity-20" to={`/about-us#consultant`}>
                      {t('nav.aboutUs_item3')}
                    </Link>
                    <Link className="bg-white px-5 py-[14px] block  duration-300 hover:duration-300 hover:text-[#004190] hover:bg-[#26ADDF] group-hover:bg-opacity-20" to={`/about-us#advisoryCommittee`}>
                      {t('nav.aboutUs_item4')}
                    </Link>
                  </div>
                </div>
              }
            </Link>

          </div>
        </div>
        <div className="flex items-center font-bolder text-sm lg:text-base  2xl:text-lg">
          <div >
            <div className={`font-bolder py-[5px] px-[12px] border-white rounded-[5px] border-[1px]`}>
              <a href="https://registration.northbundforum.com/#/register?snowId=433222649304506" target="_blank">
                <span className={`hover:text-white`}>{t('nav.register')}</span>
              </a>
              <span className="mx-[2px] lg:mx-1 xl:mx-2">｜</span>
              <Link to="/edm">
              <span className={`hover:text-white ${currentPath === '/edm' ? 'text-white' : ''}`}>{t('nav.subscribs')}</span>
              </Link>
            </div>
          </div>
          <div className="flex items-center font-bolder ml-5 2xl:ml-10">
            <img className="mr-2 h-auto w-3 lg:w-4 2xl:w-5" src={require('@/imgs/navigation/language.png')} width={20} height={20} alt="language" />
            <div>
              <span onClick={() => onSelectChange('zh')} className={`cursor-pointer hover:text-white ${locale === 'zh' ? 'text-white' : ''}`}>简</span>
              <span className="mx-[2px] lg:mx-1 xl:mx-2">｜</span>
              <span onClick={() => onSelectChange('en')} className={`cursor-pointer hover:text-white ${locale === 'en' ? 'text-white' : ''}`}>EN</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}