import React, { Suspense,useEffect } from 'react';
import logo from './logo.svg';
import './App.scss';
import { useTranslation, I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // 导入前面创建的 i18n 配置
import routes from './router/router';
import { BrowserRouter as Router, Routes, Route, useRoutes } from 'react-router-dom';
import NavigationBar from './components/NavigationBar.jsx';
import MobileNavigationBar from './components/MobileNavigationBar.jsx';
import Footer from './components/Footer.jsx';
import MobileFooter from './components/MobileFooter.js';
import { isMobile } from 'react-device-detect';

function App() {
    if(!localStorage.getItem('lang')){
      localStorage.setItem('lang','zh')
      window.location.reload()
    }

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <div className="App pt-[70px] 2xl:min-w-[1440px] overflow-x-hidden" >
          <Suspense fallback={<div>加载中...</div>}>
            {isMobile ? <MobileNavigationBar /> : <NavigationBar />}
            {/* <div className='pt-[70px]'> */}
            <div>
              <Routes>
                {routes.map((route, index) => (
                  <Route key={index} path={route.path} element={route.element} />
                ))}
              </Routes>
            </div>
            {isMobile ? <MobileFooter /> : <Footer />}
          </Suspense>
        </div>
      </Router>
    </I18nextProvider>
  );
}

export default App;
